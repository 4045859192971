import React from "react";
import * as Sentry from "@sentry/react";
import LogRocket from "logrocket";
import ReactGA from "react-ga4";

const {
  NODE_ENV = "development",
  REACT_APP_VERSION = "0.0.0",
  REACT_APP_LOGROCKET_APPID,
  REACT_APP_HOST_ENV = "development",
} = process.env;

const __DEV__ = NODE_ENV === "development";
const __AUTOMATED__ = navigator.webdriver;

let user;

/** See https://docs.sentry.io/learn/configuration */
Sentry.init({
  /** Where to send errors. This is NOT a secret. */
  dsn: "https://1ee254384b99482b915d075d8d575ec5@sentry.io/1426154",
  environment: NODE_ENV,
  release: REACT_APP_VERSION,
  /** Do not send errors in development. */
  beforeSend: __DEV__
    ? function () {
        return null;
      }
    : undefined,
  // TEMP COC-3581: disabling the report dialog, further configuration needed
  // : function (event, hint) {
  //     // Check if it is an exception, and if so, show the report dialog
  //     // we need to do this manually because of this issue https://github.com/getsentry/sentry-javascript/issues/3286#issuecomment-786981634
  //     if (event.exception) {
  //       Sentry.showReportDialog({
  //         eventId: event.event_id,
  //         user,
  //       });
  //     }
  //     return event;
  //   },
});

if (!__DEV__ && !__AUTOMATED__) {
  LogRocket.init(REACT_APP_LOGROCKET_APPID, {
    release: `raffles-${REACT_APP_HOST_ENV}`,
  });

  //get the log rocket link in the sentry errors
  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra("sessionURL", sessionURL);
    });
  });

  //link to google analytics
  LogRocket.getSessionURL(function (sessionURL) {
    ReactGA.event({
      category: "LogRocket",
      action: sessionURL,
    });
  });
}

export function setSentryUser(accountId, person, email) {
  user = {
    accountId,
    id: person.id,
    name: `${person.firstName} ${person.lastName}`,
    email,
  };
  Sentry.setUser(user);
}

export function clearSentryUser() {
  Sentry.setUser(null);
}
/**
 * Error boundary component that reports errors to https://sentry.io
 * NOTE: You don't _need_ to render this error boundary to report errors.
 * Error boundaries are primarily for catching React _rendering_ errors and
 * showing an error result UI.
 */

function ErrorFallback() {
  return (
    <div className="full-page-state">
      <img src="/images/error.svg" alt="error robot" />
      <div className="text">
        <h2 className="text-center mb-8">Something's gone wrong</h2>
        <p className="text-center mb-24">Our team has been notified</p>
        <div className="flex flex-align-center">
          <button
            className="btn btn-medium btn-light mr-16"
            style={{ fontSize: "12px", padding: "0 8px" }}
            onClick={() => Sentry.showReportDialog()}
          >
            Report feedback
          </button>
          <a
            className="btn btn-accent btn-medium"
            style={{ fontSize: "12px", padding: "0 8px" }}
            href="/granddraw"
          >
            Take me home
          </a>
        </div>
      </div>
    </div>
  );
}

export function ErrorSentry(props) {
  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback} dialogOptions={{ user }}>
      {props.children}
    </Sentry.ErrorBoundary>
  );
}
